const messages: { [key: string]: { [key: string]: string; }; } = {
    vi: {
        "not_permission": "Bạn chưa cấp quyền sử dụng máy ảnh và micro. Vui lòng cấp quyền để tiếp tục nghe cuộc gọi.",
        "back": "Quay lại",
        "disconnect": "Mất kết nối",
        "end_call": "Cuộc gọi kết thúc",
        "uploading":"Đang tải lên cuộc gọi. Vui lòng không tắt ứng dụng."
    },
    en: {
        "not_permission": "You have not granted permission to use the camera and microphone. Please grant permission to continue listening to the call.",
        "back": "Go back",
        "disconnect": "Disconnected",
        "end_call": "Call ended",
        "uploading":"Uploading call. Please do not close the app."
    }
}

export const useTranslation = (lang: string) => {
    return (key: string) => {
        const currentLang = lang === "vi" ? "vi" : "en";
        if(messages[currentLang][key]){
            return messages[currentLang][key]
        }
        return  key
    }
}
