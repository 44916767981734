import React from 'react';
import clsx from "clsx";

type Props = { children: React.ReactNode, className?: string, onClick: () => void };
const IconButton = ({children, className, onClick}: Props) => {
    return (
        <button className={clsx(className,"flex justify-center items-center rounded-full w-14 h-14")} onClick={onClick}>{children}</button>
    )
}

export default IconButton