import React, {useEffect, useMemo, useState} from 'react';
import './App.css';
import {
    User,
} from "firebase/auth";
import {Listener, ListenerStatus} from "cocall-connect";
import {loginCustomToken} from "./services";
import ListenContent from "./components/ListenContent";

declare global {
    interface Window {
        flutter_inappwebview: any;
    }
}

function App() {
    const searchParams = new URLSearchParams(window.location.search);
    const [user, setUser] = useState<any>()
    const [status, setStatus] = useState<ListenerStatus>("OFFLINE");
    const [connected, setConnected] = useState(false);
    useEffect(() => {
        const token = searchParams.get("token")
        // if (token) {
        //     console.log("start login",new Date())
        //     loginCustomToken(token as string).then(userCredential => {
        //         setUser(userCredential.user);
        //         console.log("end login",new Date())
        //     }).catch(e=>{
        //         console.log(e)
        //     })
        // }
    }, []);
    const listener = useMemo(() => {
        const token = searchParams.get("token")
        const record = searchParams.get("record");
        const orgId = searchParams.get("org");
        const callType = searchParams.get("callType");
        const callId = searchParams.get("callId");
        if (!orgId) {
            return;
        }
        const l = new Listener(user, {
            orgId,
            env: "production",
            isMobile: true,
            disableRecord: !record,
            callType: callType || undefined,
            callId: callId || undefined,
            mobileToken:token||undefined
        });
        l.onChangeStatus((s) => {
            console.log("status.....", s);
            if (s === "CANCEL_CALL" || s === "END_CALL" || s === "CONNECT") {
                onFinished()
            }
            setStatus(s);
        });
        l.onConnect((c) => {
            setConnected(c);
        })
        return l

    }, [user?.email])
    const onClose = () => {
        if (window.flutter_inappwebview) {
            window.flutter_inappwebview.callHandler('close').then();
        }

    }
    const onFinished = () => {
        console.log("onFinished")
        if (window.flutter_inappwebview) {
            window.flutter_inappwebview.callHandler('finished').then();
        }
    }

    return (
        <div className="relative w-screen h-screen flex items-center justify-center">
            <ListenContent open={true}
                           onClose={onClose}
                           listener={listener}
                           isFull
                           connected={connected} status={status}/>
        </div>
    )
}

export default App;
