import React from 'react';
import clsx from "clsx";

type Props = { label?: string, className?: string ,classRoot?:string};
const LoadingDot = ({label, className,classRoot}: Props) => {
    return (
        <div className={clsx("flex space-x-2 space-y-2 text-white justify-center mx-2 items-center",classRoot)}>
            <div className={className}>{label}</div>
            <div className="flex space-x-1 justify-center items-center">
                <div className='h-1 w-1 bg-white rounded-full animate-bounce [animation-delay:-0.3s]'></div>
                <div className='h-1 w-1 bg-white rounded-full animate-bounce [animation-delay:-0.15s]'></div>
                <div className='h-1 w-1 bg-white rounded-full animate-bounce'></div>
            </div>
        </div>
    )
}

export default LoadingDot
