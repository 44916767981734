import React, {useEffect, useRef} from 'react';
import clsx from "clsx";


type Props = {
    setLocal: (obj: HTMLVideoElement) => void,
    setRemote: (obj: HTMLVideoElement) => void,
    connected: boolean,
    hidden?: boolean
    isFull?: boolean
};
const VideoStream = ({setLocal, setRemote, connected, hidden = false, isFull}: Props) => {
    const videoCurrent = useRef(null);
    const videoRemote = useRef(null);
    useEffect(() => {
        if (videoCurrent?.current && videoRemote?.current) {
            setLocal(videoCurrent.current)
            setRemote(videoRemote.current)
        }

    }, [videoCurrent?.current, videoRemote?.current]);
    return (
        <div
            className={clsx("h-full w-full flex overflow-hidden items-center justify-center relative", hidden && "hidden")}>
            <video
                className={clsx("overflow-hidden absolute rounded-xl bg-black", connected && "rounded absolute max-w-[240px] w-2/5 h-auto right-4 top-4 z-10", !connected && isFull && "min-w-full min-h-full max-w-none max-h-none", !isFull && !connected && "w-full h-full")}
                ref={videoCurrent}
                autoPlay playsInline></video>
            <video
                className={clsx(!connected && "hidden", "bg-black overflow-hidden absolute ", isFull && "min-w-full min-h-full max-w-none max-h-none", !isFull && "w-full h-full")}
                ref={videoRemote}
                autoPlay playsInline></video>
        </div>
    )
}

export default VideoStream