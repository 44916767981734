import React from 'react';

type Props = {};
const Ping = ({}: Props) => {
    return (
        <div className="relative flex h-3 w-3 mr-2">
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-600 opacity-75"></span>
            <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
        </div>
    )
}

export default Ping