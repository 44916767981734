import {useCallback, useEffect, useState} from "react";

export const useCountTime = (): [string, () => void, () => void] => {
    const [time, setTime] = useState<string>("00:00");
    const [id, setId] = useState<any>();
    const [isStart, setStart] = useState<boolean>(false)
    useEffect(() => {
        if (isStart) {
            let t = 0;
            setId(setInterval(function () {
                t = t + 1;
                let minutes: number | string = parseInt(`${t / 60}`, 10);
                let seconds: number | string = parseInt(`${t % 60}`, 10);
                minutes = minutes < 10 ? "0" + minutes : minutes;
                seconds = seconds < 10 ? "0" + seconds : seconds;
                setTime(`${minutes}:${seconds}`)
            }, 1000));
        }
    }, [isStart])
    const start = () => {
        setStart(true);
    }
    const stop = useCallback(() => {
        if(id){
            setStart(false);
            clearInterval(id);
        }
    }, [id])
    return [time, start, stop]
}