import React, {useEffect, useRef} from 'react';

type Props = {
    setLocal: (obj: HTMLAudioElement) => void,
    setRemote: (obj: HTMLAudioElement) => void,
};
const AudioStream = ({setLocal, setRemote}: Props) => {
    const audioCurrent = useRef(null);
    const audioRemote = useRef(null);
    useEffect(() => {
        if (audioCurrent?.current && audioRemote?.current) {
            setLocal(audioCurrent.current)
            setRemote(audioRemote.current)
        }

    }, [audioCurrent?.current, audioRemote?.current]);
    return (
        <div className="hidden">
            <audio ref={audioCurrent} autoPlay playsInline></audio>
            <audio ref={audioRemote} autoPlay playsInline></audio>
        </div>
    )
}

export default AudioStream