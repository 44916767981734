import React, {useEffect, useState} from 'react';

import VideoStream from '../VideoStream';
import {Listener, ListenerStatus} from "cocall-connect";

import clsx from "clsx";
import {Player} from '@lottiefiles/react-lottie-player';

import notifyImg from "../../assets/notify.svg"
import {useCountTime} from "../../hooks/useCountTime";
import IconButton from "../IconButton";
import CloseIcon from "../icons/CloseIcon";
import Ping from "../Ping";
import AudioStream from "../AudioStream";
import LoadingDot from "../LoadingDot";
import CameraswitchRoundedIcon from "../icons/CameraswitchRoundedIcon";
import CallEndRoundedIcon from "../icons/CallEndRoundedIcon";
import MicRoundedIcon from "../icons/MicRoundedIcon";
import MicOffRoundedIcon from "../icons/MicOffRoundedIcon";
import Logo from "../../assets/logo.png"
import ListenerBg from "../../assets/listener.jpg"
import {useTranslation} from "../../hooks/useTranslation";
import calling from "../../assets/calling.json"


type Props = {
    open: boolean,
    connected: boolean,
    onClose?: () => void,
    listener?: Listener,
    isFull?: boolean,
    status: ListenerStatus
};
export const ListenContent = ({onClose, isFull, listener, connected, status}: Props) => {
    const searchParams = new URLSearchParams(window.location.search);
    const t = useTranslation(searchParams.get("lang") || "vi")

    const callType = searchParams.get("callType");
    const [devicesError, setDevicesError] = useState(false);
    const [localVideo, setLocalVideo] = useState<HTMLVideoElement | HTMLAudioElement>()
    const [remoteVideo, setRemoteVideo] = useState<HTMLVideoElement | HTMLAudioElement>();
    const [isMic, setMic] = useState(true);
    const [time, start] = useCountTime();
    const [checkDisconnect, setCheckDisconnect] = useState(false)
    const [uploading, setUploading] = useState(false)

    useEffect(() => {
        if (connected) {
            setCheckDisconnect(true)
        }
    }, [connected]);

    useEffect(() => {
        if (status === "CONNECT") {
            start()
        }
    }, [status]);
    useEffect(() => {
        window.onbeforeunload = function () {
            return "Đang trong cuộc gọi";
        };
        return () => {
            window.onbeforeunload = function () {
            };
        }
    }, []);
    useEffect(() => {
        if (localVideo && remoteVideo && listener) {
            listener.onUpload = (upload) => {
                console.log("uploading......", upload)
                setUploading(upload);
            }
            listener.addVideoElement(localVideo, remoteVideo);
            if (callType) {
                listener?.acceptCall(true).then().catch(e => {
                    onFinished();
                    console.log("acceptCall error", e)
                    if (e.code === "devices") {
                        listener.endCall().then();
                        setDevicesError(true);
                    }
                })
            }
        }
    }, [localVideo, remoteVideo, listener]);
    const onFinished = () => {
        console.log("onFinished")
        if (window.flutter_inappwebview) {
            window.flutter_inappwebview.callHandler('finished').then();
        }
    }
    const endCall = () => {
        if (listener) {
            setCheckDisconnect(false);
            listener.endCall().then(() => {
            });
        }
    }
    const muteMic = () => {
        if (listener) {
            listener.muteMic();
            setMic(!isMic)
        }
    }
    const turnCamera = () => {
        if (listener) {
            listener.turnCamera().then()
        }
    }
    return (
        <div className="w-full h-full bg-no-repeat bg-cover flex flex-col items-center justify-center"
             style={!devicesError ? {
                 backgroundImage: `url(${ListenerBg})`,
                 backgroundPositionX: "34%"
             } : {}}>

            {devicesError && (
                <div className="flex flex-col items-center justify-center px-4">
                    <img src={notifyImg} alt="" width={400} height={400}/>
                    <div className="max-w-[400px] text-center mt-2">
                        {t("not_permission")}
                    </div>
                    {onClose && <button className="mt-4" onClick={onClose}>{"Quay lại"}</button>}
                </div>
            )}
            {!devicesError && (
                <>
                    <div className="fixed top-0 left-0 right-0 bottom-0 bg-black/40"/>
                    <div className="absolute top-6 flex flex-col items-center z-50">
                        {status === "CONNECT" && (
                            <div className="flex items-center text-white">
                                <Ping/><p>{time}</p>
                            </div>
                        )}
                    </div>
                    {callType === "video" && (
                        <VideoStream setLocal={setLocalVideo}
                                     isFull={isFull}
                                     hidden={status === "END_CALL" || status === "CANCEL_CALL"}
                                     setRemote={setRemoteVideo} connected={connected}/>
                    )}
                    {callType === "audio" && (
                        <AudioStream setLocal={setLocalVideo} setRemote={setRemoteVideo}/>
                    )}
                    {status === "CONNECT" && callType === "audio" && (<Player
                        loop
                        controls={true}
                        autoplay
                        src={calling}
                        style={{height: '250px', width: '250px'}}
                    />)}
                    {status === "CONNECT" && !connected && checkDisconnect && (
                        <div className="absolute">
                            <LoadingDot label={t("disconnect")}/>
                        </div>
                    )}
                    {(status === "END_CALL" || status === "CANCEL_CALL") && (
                        <>
                            <div className="relative z-10 rounded-3xl flex flex-col items-center justify-items-center">
                                <img src={Logo} width={200} height={200} alt="cocall"/>
                                <h5 className="text-white text-center">
                                    {t("end_call")}
                                </h5>
                            </div>
                        </>
                    )}
                    <div className="absolute bottom-8 flex">
                        {status === "CONNECT" && (
                            <>
                                <IconButton
                                    className={clsx(callType === "audio" && "invisible", "mr-4 bg-black/20")}
                                    onClick={turnCamera}>
                                    <CameraswitchRoundedIcon/>
                                </IconButton>
                                <IconButton onClick={endCall} className="bg-red-500">
                                    <CallEndRoundedIcon/>
                                </IconButton>
                                <IconButton className="bg-black/20 ml-4"
                                            onClick={muteMic}>
                                    {isMic ? <MicRoundedIcon/> :
                                        <MicOffRoundedIcon/>}
                                </IconButton>
                            </>
                        )}
                        {uploading && (status === "END_CALL" || status === "CANCEL_CALL") &&
                            <LoadingDot className="text-lg text-center text-white"
                                        classRoot="flex-col"
                                        label={t("uploading")}></LoadingDot>}

                        {(status === "END_CALL" || status === "CANCEL_CALL") && onClose && !uploading && (
                            <IconButton onClick={onClose} className="bg-red-500 ">
                                <CloseIcon/>
                            </IconButton>
                        )}
                    </div>
                </>
            )}

        </div>
    )
}
export default ListenContent
